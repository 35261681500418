import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DataManagerService } from '../services/DataManager/data-manager.service';
import { BaseListComponent } from '../shared/abstract/base-list.component';
import { AppConstants } from '../shared/constants/app-constants';
import { PermissionConstants } from '../shared/constants/permission-constants';
import { debounceTime } from 'rxjs/operators';
import { HeaderConstants } from '../shared/constants/header-constants';
import { Subject } from 'rxjs';
import { MessageDialogComponent } from '../dialogs/message-dialog/message-dialog.component';
@Component({
  selector: 'app-list-duplicate-payments',
  templateUrl: './list-duplicate-payments.component.html',
  styleUrl: './list-duplicate-payments.component.css',
})
export class ListDuplicatePaymentsComponent
  extends BaseListComponent
  implements OnInit
{
  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  deleteUrl: string;
  constructor(
    datamanagerService: DataManagerService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    super();
    this.page = 1;
    this.rows = 10;
    this.service = datamanagerService;
    this.getTableSettings();
    this.searchHits = new Subject<void>();
    this.getListUrl = AppConstants.BASE_URL + '/getDuplicatePayment';
    this.columnDefinition = HeaderConstants.duplicatePaymentsHeader;
    this.headings = 'Duplicated Payments';
    this.permissionsCheck = {
      view_permission: true,
    };
  }
  ngOnInit(): void {
    this.loader = true;
    this.searchHits.pipe(debounceTime(600)).subscribe((res) => {
      this.loader = true;
      if (!this.isNullOrUndefined(this.searchText)) {
        console.log('serch jsjsdf', this.setUrl());
      }
    });
    this.setUrl();
  }

  onNotifySelected(selectedRows: object[]) {
    console.log(selectedRows);
  }
  protected getListRes(res: any): void {
    this.loader = true;
    this.rowData = [];
    if (res['status'] == 'success') {
      this.resultsLength = res.data.pagination.total;

      this.rowData = res.data.rowData;
      console.log(this.rowData);
      this.loader = false;
    } else {
      this.loader = false;
    }
  }

  protected getListErr(err: any): void {
    console.log(err);
    this.loader = false;
  }
  getActions($event: any) {}
}
