import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataManagerService } from '../services/DataManager/data-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../shared/constants/app-constants';
import { CourseRegisterDialogComponent } from 'src/app/dialogs/course-register-dialog/course-register-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BaseListComponent } from 'src/app/shared/abstract/base-list.component';
import { HeaderConstants } from 'src/app/shared/constants/header-constants';
import { SchoolComponent } from 'src/app/school/school/school.component';
import { PermissionConstants } from 'src/app/shared/constants/permission-constants';
import { ColumnSettingsModel } from 'src/app/shared/constants/table-settings.model';
import { DatePipe } from '@angular/common';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ApprovalDialogComponent } from 'src/app/dialogs/approval-dialog/approval-dialog.component';
import { FuneralPaymentDialogComponent } from 'src/app/dialogs/funeral-payment-dialog/funeral-payment-dialog.component';
import { MessageDialogComponent } from 'src/app/dialogs/message-dialog/message-dialog.component';
import { ImageShowerDialogComponent } from 'src/app/dialogs/image-shower-dialog/image-shower-dialog.component';

@Component({
  selector: 'app-courses-register-page',
  templateUrl: './courses-register-page.component.html',
  styleUrls: ['./courses-register-page.component.css'],
})
export class CoursesRegisterPageComponent
  extends BaseListComponent
  implements OnInit
{
  public view_courseurl: string = AppConstants.BASE_URL + '/course';
  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  public courseLanguage: any[] = [
    { id: 1, name: 'English' },
    { id: 2, name: 'Arabic' },
    { id: 3, name: 'Others' },
  ];
  course_id: any;
  course_Registered_Details: any[] = [];
  public course_registered_students: any[] = [];
  public CoursecolumnDefinition: ColumnSettingsModel[] = [];
  showExport: boolean = false;
  constructor(
    dataManagerService: DataManagerService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private datePipe: DatePipe,
  ) {
    super();
    this.service = dataManagerService;
    this.columnDefinition = HeaderConstants.ViewCourseRegisterHeader;
    this.CoursecolumnDefinition = HeaderConstants.courseRegisterStudentHeader;
    this.route.params.subscribe((params) => {
      this.course_id = params['course_id'];
      this.getListUrl = this.view_courseurl + '/' + this.course_id;
    });
    this.headings = 'Courses Registration';
    this.getFormData();
    this.getTableSettings();
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {}
  getFormData() {
    this.formLoader = true;
    this.route.queryParams.subscribe((params) => {
      if (this.course_id) {
        this.service
          .getById(this.view_courseurl + '/' + this.course_id)
          .subscribe({
            next: (res: any) => {
              if (res['status'] == 'success') {
                if (res.data) {
                  this.data = res.data.row;
                  const updatedLanguages = this.data.language.map(
                    (id: string) => {
                      if (id === '3') {
                        return this.data.other_language; // Use this.data.other_language when id is '3'
                      }

                      const language = this.courseLanguage.find(
                        (lang) => lang.id.toString() === id,
                      );
                      return language ? language.name : 'Unknown';
                    },
                  );

                  // Replace this.data.language with the updated values
                  this.data.language = updatedLanguages;

                  this.data.course_fee = Number(
                    this.data.course_fee,
                  ).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  });
                }
                this.formLoader = false;
                this.setUrl();
              } else {
                this.formLoader = false;
                this.service.showSnackBar(res.message, 'ok', 3000);
              }
            },
            error: (error) => {
              this.formLoader = false;
              this.service.showSnackBar(error.message, 'ok', 3000);
            },
          });
      } else {
        this.formLoader = false;
      }
    });
  }
  registerCourse() {
    if (this.data) {
      const paymentUrl = AppConstants.BASE_URL + AppConstants.REGISTER_COURSE;
      const dialogRef = this.dialog.open(CourseRegisterDialogComponent, {
        data: {
          Header: 'Course Registration',
          url: paymentUrl,
          data: this.data,
          buttonText: {
            ok: 'Proceed',
            cancel: 'Cancel',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: any) => {
        if (confirmed && confirmed != 'recurring') {
          this.router.navigate(['../../checkout'], {
            queryParams: {
              id: confirmed.payable_id,
              type: 'courses',
              course_id: this.data.id,
            },
            relativeTo: this.route,
          });
        } else if (confirmed == false) {
          this.router.navigate(['/course/' + this.course_id]);
        } else {
        }
      });
    }
  }

  protected getListRes(res: any): void {}
  protected getListErr(err: any): void {
    console.log(err);
  }
  exportexcel() {
    this.loadFlag2 = true;
    const exportUrl =
      AppConstants.BASE_URL + '/course_students/export/' + this.course_id;
    this.service.exportToExcel(exportUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 200) {
          this.loadFlag2 = false;
          this.downloadExcel(res);
          this.service.showSnackBar('File is loading', 'ok', 3000);
        } else {
          this.loadFlag2 = false;
          this.service.showSnackBar(res.message, 'ok', 3000);
        }
      },
      error: (error) => {
        this.loadFlag2 = false;
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
  onTabChange(event: MatTabChangeEvent) {
    if (event.tab.textLabel == 'Course Registered Students') {
      this.showExport = true;
    } else {
      this.showExport = false;
    }
  }
  getActions(data: any) {
    if (data.actions === 'Verify Payment') {
      const verifyPaymentUrl =
        AppConstants.BASE_URL + '/course/coursePayment/' + data.data.id;
      const dialogRef = this.dialog.open(ApprovalDialogComponent, {
        data: {
          message: 'Payment is done through E-transfer!',
          url: verifyPaymentUrl,
          data: data,
          buttonText: {
            ok: 'Proceed',
            cancel: 'Cancel',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.setUrl();
        }
      });
    } else if (data.actions === 'payment') {
      const paymentUrl =
        AppConstants.BASE_URL +
        AppConstants.ADD_COURSE +
        '/coursePaymentCheckout/' +
        data.data.id;
      const dialogRef = this.dialog.open(FuneralPaymentDialogComponent, {
        data: {
          message: 'Do you want to pay this Course?',
          Header: 'Course Payment',
          url: paymentUrl,
          course: true,
          data: data.data,
          buttonText: {
            ok: 'Proceed',
            cancel: 'Cancel',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: any) => {
        if (confirmed) {
          this.router.navigate(['../checkout'], {
            queryParams: { id: confirmed.payable_id },
            relativeTo: this.route,
          });
        } else if (confirmed == false) {
          this.setUrl();
        } else {
        }
      });
    } else if (data.actions === 'downloadPDF') {
      const downloadPDFUrl =
        AppConstants.BASE_URL +
        '/payment/downloadpdf/' +
        data.data.transcation_id;
      const dialogRef = this.dialog.open(ApprovalDialogComponent, {
        data: {
          message: 'Do you want to download this Course Receipt?',
          url: downloadPDFUrl,
          data: data,
          buttonText: {
            ok: 'Proceed',
            cancel: 'Cancel',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.router
            .navigateByUrl('home', { skipLocationChange: true })
            .then(() => {
              this.router.navigate([location.pathname], {
                queryParams: { id: data.data.course_id },
                relativeTo: this.route,
              });
            });
        }
      });
    } else if (data.actions === 'delete') {
      const courseRegisterDeleteUrl =
        AppConstants.BASE_URL + '/course_register/delete';
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        data: {
          message: 'Do you want to delete this Course Registered Details?',
          buttonText: {
            ok: 'Ok',
            cancel: 'Close',
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          let payload = {
            ids: [data.data.id],
          };
          this.service.delete(courseRegisterDeleteUrl, payload).subscribe({
            next: (res: any) => {
              if (res['status'] == 'success') {
                this.service.showSnackBar(res.message, 'ok', 3000);
                this.router
                  .navigateByUrl('home', { skipLocationChange: true })
                  .then(() => {
                    this.router.navigate([location.pathname], {
                      queryParams: { id: data.data.course_id },
                      relativeTo: this.route,
                    });
                  });
              } else {
                this.service.showSnackBar(res.message, 'ok', 3000);
              }
            },
            error: (error) => {
              this.service.showSnackBar(error.message, 'ok', 3000);
            },
          });
        }
      });
    }
  }
  imageView() {
    let myImg: any = document.querySelector('#imgId');
    var realWidth = myImg.naturalWidth;
    var realHeight = myImg.naturalHeight;
    console.log(realHeight, realWidth);
    const dialogRef = this.dialog.open(ImageShowerDialogComponent, {
      data: {
        url: this.data.thumbnail,
        width: realWidth,
        hight: realHeight,
        buttonText: {
          cancel: 'Cancel',
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
      }
    });
  }
  addDays(text: string): string {
    if (!text.includes('days')) {
      text += ' days';
    }
    return text;
  }
}
